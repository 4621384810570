import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';


import { Container } from '../../../../components/ui';
import Loading from '../../../../components/dotsLoading';
import WaitAndSubscribeModal from '../../../../components/modals/waitAndSubscribeModal';
import { 
    Component,
    Block,
    Title, 
    Text,
    Form,
    PhoneBlock,
    PhoneCode,
    PhoneInput,
    SendButton,
    LoadingBlock,
    PhoneContainer,
    PhoneError,
} from './components';

import { callCenterPhone } from '../../../../configs';
import { addRequestOrder } from '../../../../api';

const HowToOrderComponent = ({ howToOrderRef }) => {
    const [loading, setLoading] = useState(false);
    const [showSubscribeModal, setShowSubscribe] = useState(false);

    const handleFormSubmit = async (values) => {
        var xx = Cookies.get("sendNumber");
        if(xx!=="sended") {
            setLoading(true);

            window.gtag('event', 'click', {'send_number': values.phone});
            
            await addRequestOrder(values.phone);

            setLoading(false);
            setShowSubscribe(true);
            Cookies.set("sendNumber", "sended", {expires: 0.08 });
        } else {
            alert("Попробуйте через час");
        }
    };

    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: yup.object().shape({
            phone: yup.string().required('Пожалуйста, введите номер телефона').length(9, 'Номер телефона должен состоять из 9 цифр').nullable()
        }),
        onSubmit: handleFormSubmit,
    });

    const handleClicNumber = () => {
        console.log("clickToCall");
        window.gtag('event', 'call', {'callto': "446102323"});

    }

    const handlePhoneInput = (value) => {
        const number = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setFieldValue('phone', number);
    };

    const handleSubscribeClose = () => {
        setFieldValue('phone', '');
        
        setShowSubscribe(false);
    }

    return (
        <Component ref={howToOrderRef}>
            <Container>
                <Block>
                    <Title>
                        Как заказать?
                    </Title>

                    <Text>
                        Номер для заказа <b><a onClick={() => handleClicNumber()} style={{color: "white",fontSize:"21px"}} href="tel:446102323">446102323</a></b>
                    </Text>
                    <br />

                    <Text>
                        или Вы можете оставить номер телефона и наш оператор перезвонит Вам в течении 3 минут
                    </Text>

                    <Form>
                        <PhoneContainer>
                            <PhoneBlock error={errors.phone}>
                                <PhoneCode>
                                    +992
                                </PhoneCode>

                                <PhoneInput 
                                    type="tel"
                                    value={values.phone}
                                    onChange={(event) => handlePhoneInput(event.target.value)}
                                    maxLength="9"
                                />
                            </PhoneBlock>
                            <PhoneError>{errors.phone}</PhoneError>
                        </PhoneContainer>
                        
                        <SendButton 
                            onClick={() => handleSubmit()}
                            disabled={errors.phone}    
                        >
                            Отправить
                        </SendButton>
                    </Form>

                    {showSubscribeModal && (
                        <WaitAndSubscribeModal 
                            onClose={() => handleSubscribeClose()}
                        />
                    )}
                </Block>
            </Container>

            {loading && (
                <LoadingBlock>
                    <Loading />
                </LoadingBlock>
            )}
        </Component>
    )
};

export default HowToOrderComponent;
